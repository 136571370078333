<template>
  <div  style="background:#FFF">
    <div class="header MT_5" @click="goBack">
      <van-icon size="20" name="arrow-left" />
      <span>企业信息</span>
    </div>
    <div style="display:flex;align-items:center;justify-content:center">
        <div class="textC MT_10" @click="chooseLocaleImg">
          <img v-if="avatar_url == ''" src="../assets/tou-icon@2x.png" alt="">
          <img v-else :src="avatar_url" alt="">
          <div class="MT_2">企业头像</div>
        </div> 
    </div>
    <input id='choosePhotoAlbum' @change="getFile" v-show="false" type="file" accept="image/*" className='choosePhotoAlbum'   />
    
    <div class="MT_5">
      <div class="tabItem">
        <div>企业名称</div>
          <van-field
            style="width:70%;"
            v-model="enterprise_name"
            placeholder="输入企业名称"
            input-align="right"
          />
      </div>
      <div class="tabItem">
        <div>企业管理人</div>
          <van-field
            style="width:70%;"
            v-model="enterprise_manager"
            placeholder="输入企业管理人"
            input-align="right"
          />
      </div>
      <div class="tabItem">
        <div>企业联系人手机</div>
          <van-field
            style="width:70%;"
            v-model="mobile"
            placeholder="输入企业联系人手机"
            input-align="right"
          />
      </div>
      <div class="tabItem">
        <div>企业账号</div>
          <van-field
            disabled
            style="width:70%;"
            v-model="userInfo.account"
            placeholder="输入企业账号"
            input-align="right"
          />
      </div>
      <div class="tabItem">
        <div>密码</div>
          <van-field
            style="width:70%;"
            v-model="password"
            type="password"
            placeholder="输入密码"
            input-align="right"
          />
      </div>
    </div>
    
    <div class="W_80 textC loginBtn1 " @click="submit">提交</div>
  </div>
</template>
<script>
import {mapState,mapActions} from "vuex"
import { Toast } from 'vant';
export default {
  data(){
    return {
      password : "", //密码
      enterprise_name : "", //企业名
      enterprise_manager : "", //企业负责人
      mobile : "", //手机
      avatar_url : "", //头像
    }
  },
  computed:{
    ...mapState({
       userInfo:  (state) => state.userInfo,
    }),
  },
  mounted(){
      this.getAccountInfo({
        reqType: "login", //请求类型
        account: this.userInfo.account //账户名
      }).then((res)=>{
        let {password ,enterprise_name ,enterprise_manager , mobile ,avatar_url} = res.data.accountInfo
        this.enterprise_name =   enterprise_name
        this.enterprise_manager =   enterprise_manager
        this.mobile =   mobile
        this.avatar_url =   avatar_url
      })
  },
  methods:{
    ...mapActions(["editManageCompany","getAccountInfo","upload1"]),
    getFile( ){
      const localFile = document.querySelector('#choosePhotoAlbum').files[0]; // 从手机相册选择
      this.upload1({
        file: localFile,
      }).then((res)=>{
        this.avatar_url = res.data
      })
    },
    chooseLocaleImg(){
      const chooseImg = document.getElementById('choosePhotoAlbum');
      chooseImg.click();
    },
    afterRead(file){
      console.log(file)
    },
    goBack(){
      this.$router.go(-1)
    },
    submit(){
      if(this.password == "") {
        this.editManageCompany({
        reqType : "Login", //请求类型
        enterprise_name : this.enterprise_name, //企业名
        enterprise_manager : this.enterprise_manager, //企业负责人
        mobile : this.mobile, //手机
        avatar_url : this.avatar_url, //头像
        id  : this.userInfo.id //企业信息id
      }).then((res)=>{
        if(res.code == 0){
          Toast.success("修改成功")
          this.$store.commit("SET_COMPANY_NAME",this.enterprise_name)
          this.$store.commit("SET_COMPANY_AVATAR",this.avatar_url)
          this.$store.commit("SET_COMPANY_MAN",this.enterprise_manager)
          setTimeout(()=>{
            this.$router.go(-1)
          },1000)
        }
      })
      } else {
        this.editManageCompany({
        reqType : "Login", //请求类型
        password : this.password, //密码
        enterprise_name : this.enterprise_name, //企业名
        enterprise_manager : this.enterprise_manager, //企业负责人
        mobile : this.mobile, //手机
        avatar_url : this.avatar_url, //头像
        id  : this.userInfo.id //企业信息id
      }).then((res)=>{
        if(res.code == 0){
          Toast.success("修改成功")
          this.$store.commit("SET_COMPANY_NAME",this.enterprise_name)
          this.$store.commit("SET_COMPANY_AVATAR",this.avatar_url)
          this.$store.commit("SET_COMPANY_MAN",this.enterprise_manager)
          setTimeout(()=>{
            this.$router.go(-1)
          },1000)
        }
      })
      }
      
    },
  }
}
</script>
<style scoped>
img{
  width:7rem;
  height:7rem;
  border-radius: 50%;
}
.tabItem{
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom:0.1rem solid #E9E9E9;
  margin:1rem;
}
.loginBtn1{
  margin:0 auto;
  height: 3rem;
  line-height: 3rem;
  background: #151B62;
  border-radius: 1rem;
  color:#fff;
  margin-top:3rem;
}
</style>